//underwriter
.component-wrapper {
  .card {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    border: none;
    padding: 1.25rem;
  }

  .nav-tabs {
    padding: 8px 8px 0 8px;
    background-color: $gray-700;
    border: none;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.13);

    .nav-item {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    .nav-link {
      border-top: 5px solid #929292;
      background-color: #929292;
      color: #ffffff;
      padding: 0.5rem 1rem 0.75rem 1rem;

      &:hover {
        border-color: #929292;
      }

      &.active {
        background-color: #ffffff;
        border-top: 5px solid $orange;
        color: $gray-900;

        &:hover {
          border-color: #ffffff;
          border-top-color: $orange;
        }
      }
    }
  }

  .tab-content {
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.13);
  }

  .widget-content-wrapper {
    @media (max-width: 767px) {
      flex-direction: column;

      .widget-content-right {
        margin: 0;

        .widget-numbers {
          font-size: 1.2rem;
          overflow-wrap: anywhere;
        }
      }
    }
  }
}
