// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$new-silver-admin-primary: mat.define-palette(mat.$indigo-palette);
$new-silver-admin-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$new-silver-admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$new-silver-admin-theme: mat.define-light-theme(
  (
    color: (
      primary: $new-silver-admin-primary,
      accent: $new-silver-admin-accent,
      warn: $new-silver-admin-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($new-silver-admin-theme);

@import 'assets/base';
@import 'assets/scss/variables';
@import '@angular/cdk/overlay-prebuilt.css';

// remove bootstrap-generated modal backdrop from <body>
body > div.modal-backdrop.show {
  display: none !important;
}

// Theme customs
:root,
:root[data-theme='ns'] {
  --primary: #{$color-primary};
  --primary-tint: #{$color-primary-tint};
  --primary-hover: #{$color-primary-hover};
  --secondary: #{$color-secondary};
  --secondary-hover: #{$color-secondary-hover};
  --color-gradient: #{$gradient-blue-dark};
}
:root[data-theme='wl'] {
  --primary: #{$color-primary-wl};
  --primary-tint: #{$color-primary-tint-wl};
  --primary-hover: #{$color-primary-hover-wl};
  --secondary: #{$color-secondary-wl};
  --secondary-hover: #{$color-secondary-hover-wl};
  --color-gradient: #{$color-gradient-wl};
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
